import React, { Component } from 'react';
import RegisterOne from './RegisterOne';
import RegisterTwo from './RegisterTwo';
import RegisterThree from './RegisterThree';
export default class Register extends Component {


  constructor(props) {
    super(props);
    this.state = {
      currentStep:1,
      lastStep:3,
      firstName:'',
      lastName:'',
      email:'',
      password:'',
      phone:'',
      other:''
    }
    this.handleChange = this.handleChange.bind(this)
  }
  componentDidMount() {
  }
  handleChange(event) {
    const {name, value} = event.target
    this.setState({
      [name]: value
    })
  }
  _next() {
    let currentStep = this.state.currentStep
    currentStep = currentStep + 1;
    this.setState({currentStep:currentStep})
  }
  _previous() {
    let currentStep = this.state.currentStep
    currentStep = currentStep - 1;
    this.setState({currentStep:currentStep})
  }

    render() {
      console.log(this.state);
      return (
        <div style={styles.registerContainer}>
        <RegisterOne
          currentStep={this.state.currentStep}
          handleChange={this.handleChange}
          firstName={this.state.firstName}
          lastName={this.state.lastName}
         />
         <RegisterTwo
           currentStep={this.state.currentStep}
           handleChange={this.handleChange}
           email={this.state.email}
           password={this.state.password}
          />
          <RegisterThree
            currentStep={this.state.currentStep}
            handleChange={this.handleChange}
            phone={this.state.phone}
            other={this.state.other}
          />

          <div onClick={()=>this._next()} style={styles.nextButton}>next</div>
          {(this.state.currentStep > 1) &&
            <div onClick={()=>this._previous()} style={styles.nextButton}>previous</div>
          }
        </div>
      )
    }
}

const styles = {
  registerContainer : {

  },
  loginForm: {
    background:'#f26134',
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    padding:15,
    borderRadius:5
  },
  inputContainer: {
    marginBottom:50
  },
  formInput : {
    minWidth:350,
    borderBottom:'1px solid #f26134',
    borderTop:'none',
    borderLeft:'none',
    borderRight:'none',
    background:'rgba(255,255,255,0)',
    color:'#f26134',
    marginTop:15
  },
  inputLabel : {
    color:'#f26134',
    fontSize:34
  },
  button : {
    marginTop:15,
    padding:8,
    background:'red'
  },
  nextButton : {
    background:'#f26134',
    color: 'white',
    padding:'8px 20px 8px 20px',
    marginTop:45,
    width:350,
    textAlign:'center',
    borderRadius:'5px'
  },
  actionBar:{
    display:'flex',
    flexDirection:'row',
    width:'100%',
    justifyContent:'space-evenly',
    marginTop:13
  },
  actionItem: {
    fontSize:12
  },
  image : {
    width:200
  }
}
