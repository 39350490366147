import React, { Component } from 'react';
//import logo from '../../tourystaLogo.png';
import { withRouter } from "react-router-dom";
import {baseUrl} from '../../config.js';
import { connect } from "react-redux";
import { registerUser } from "../../redux/actions/authActions";
import { loginUser } from "../../redux/actions/authActions";
import moment from 'moment';
import axios from 'axios';
import CSVReader from 'react-csv-reader'

class Import extends Component {

  constructor(props) {
    super(props);
    this.state = {
      links:[],
      dumped:false,
      imported:false,
      length:0
    }
  }
  componentDidMount() {
  }
  async getData(data) {
    return Promise.all(data.map((link,index)=>this.makeit(link,index)))
  }
  async makeit(link,index) {
    try {
        if(link[0] !=="") {
          var tags = link[1].split(" ").map((tag)=>{
            return  tag ? {
              "id":tag,
              "text":tag
            } : ''
          })
          var output = {
            link : link[0],
            tags: tags
          }
          var success = await axios.post(baseUrl + '/links/add',{
            link:link[0],
            tags:tags
          });
          console.log('IMPORTED at ', index)
        }
    } catch(error) {
      console.log("error here",error)
    }
  }
  otherData(data) {
    data.forEach((link,i)=>{
      setTimeout(()=>{
        if(link[0] !=="") {
          var tags = link[1].split(" ").map((tag)=>{
            return  tag ? {
              "id":tag,
              "text":tag
            } : ''
          })
          var output = {
            link : link[0],
            tags: tags
          }
          axios.post(baseUrl + '/links/add',{
            link:link[0],
            tags:tags
          })
          .then(result=>{
            console.log('success at ' + i,result);
          })
          .catch(error=>{
            console.error('error at ' + i,error);
          })
        }
      },i*500)
    })
  }
    render() {
      return (
        <>
        <div>import screen</div>

          <div>{this.state.length} records</div>
        <CSVReader onFileLoaded={
          (data, fileInfo) => {
            this.setState({
              length:data.length
            })

            this.otherData(data)
          }
        }
        />
        </>
      )
    }
}

const styles = {
  homeContainer : {
    display:'flex',
    flexDirection:'column',
    height:'calc(90vh - 140px)',
    justifyContent:'center',
    alignItems:'center'
  }
}
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps,{ registerUser, loginUser })(withRouter(Import))
