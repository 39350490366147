import React, { Component } from 'react';

export default class RegisterInput extends Component {


  constructor(props) {
    super(props);
    this.state = {
      focus:false
    }
  }
  componentDidMount() {
  }
    render() {
      return (
          <div style={styles.inputContainer}>
            <div style={{color:'#f26134',fontSize:this.state.focus ? 44 : 34}}>
            {this.props.label}
            </div>
            <input
              style={styles.formInput}
              value={this.props.value}
              name={this.props.name}
              onChange={(e)=>{console.log(e.target.name);this.props.onChange(e)}}
//              onFocus={()=>{this.setState({focus:true})}}
//              onBlur={()=>{this.setState({focus:false})}}
              onLoseFocus={()=>{console.log('leave foxus')}}
            />
          </div>
      )
    }
}

const styles = {
  registerContainer : {

  },
  loginForm: {
    background:'#f26134',
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    padding:15,
    borderRadius:5
  },
  inputContainer: {
    marginBottom:50
  },
  formInput : {
    minWidth:350,
    borderBottom:'1px solid #f26134',
    borderTop:'none',
    borderLeft:'none',
    borderRight:'none',
    background:'rgba(255,255,255,0)',
    color:'#f26134',
    marginTop:15
  },
  inputLabel : {
    color:'#f26134',
    fontSize:34
  },
  button : {
    marginTop:15,
    padding:8,
    background:'red'
  },
  nextButton : {
    background:'#f26134',
    color: 'white',
    padding:'8px 20px 8px 20px',
    marginTop:45,
    width:350,
    textAlign:'center',
    borderRadius:'5px'
  },
  actionBar:{
    display:'flex',
    flexDirection:'row',
    width:'100%',
    justifyContent:'space-evenly',
    marginTop:13
  },
  actionItem: {
    fontSize:12
  },
  image : {
    width:200
  }
}
