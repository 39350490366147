import React, { Component } from 'react';
import { connect } from "react-redux";
import { loginUser } from "../../redux/actions/authActions";
const logo = require('../../logo_circle.png');
class Login extends Component {


  constructor(props) {
    super(props);
    this.state = {
      email:'',
      password:''
    }
  }
  componentDidMount() {
  }
  login() {
    console.log(this.state.email,this.state.password);
    const userData = {
      email: this.state.email,
      password: this.state.password

    };
    this.props.loginUser(userData)
    .then(result=>{
      this.props.history.push('/')
    })
    .catch(error=>{
      alert('login incorrect')
    })
  }
    render() {
      return (
        <div style={styles.loginContainer}>
          <div style={styles.loginForm}>
          <img src={logo} style={styles.logo} alt="logo"/>
            <input
              style={styles.formInput}
              value={this.state.email}
              onChange={(e)=>this.setState({email:e.target.value})}
              placeholder='email'
            />
            <input
              style={styles.formInput}
              value={this.state.password}
              onChange={(e)=>this.setState({password:e.target.value})}
              placeholder='password'
            />
            <button onClick={()=>this.login()} style={styles.whiteButton}>login</button>
          </div>
        </div>
      )
    }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginUser }
)(Login);

const styles = {
  loginContainer : {
    display:'flex',
    height:'calc(100vh - 150px)',
    justifyContent:'center',
    alignItems:'center'
  },
  logo: {
    width:150
  },
  loginForm: {
    background:'lightblue',
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    padding:15,
    borderRadius:5
  },
  formInput : {
    margin:8,
    minWidth:350,
    borderBottom:'1px solid white',
    borderTop:'none',
    borderLeft:'none',
    borderRight:'none',
    background:'rgba(255,255,255,0)',
    color:'black'
  },
  button : {
    marginTop:15,
    padding:8,
    background:'red'
  },
  whiteButton : {
    background:'white',
    color: 'black',
    padding:'8px 20px 8px 20px',
    marginTop:15,
    marginLeft:8,
    marginRight:8,
    marginBottom:15,
    width:'calc(100% - 16px)',
    textAlign:'center',
    borderRadius:'5px',
    borderColor:'white'
  },
  actionBar:{
    display:'flex',
    flexDirection:'row',
    width:'100%',
    justifyContent:'space-evenly',
    marginTop:13
  },
  actionItem: {
    fontSize:12
  },
  image : {
    width:200
  }
}
