import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { BrowserRouter as Link } from "react-router-dom";
import { connect } from "react-redux";
import {NavDropdown} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { logoutUser } from "../../redux/actions/authActions";
import { loginUser } from "../../redux/actions/authActions";

const logo = require('../../logo_circle.png');
class TopNav extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {
  }
  login() {
    const userData = {
      email: 'jasonseck@gmail.com',
      password: 'thegorillajumps'

    };
    this.props.loginUser(userData);
  }
    render() {
      const { isAuthenticated, user } = this.props.auth;
      console.log(this.props);
      return  this.props.auth.isAuthenticated ? (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div onClick={()=>{this.props.history.push('/')}} style={{cursor:'pointer',marginRight:15}}>
            <img src={logo} style={styles.logo} alt="logo"/>
          </div>
          <div style={{cursor:'pointer'}} onClick={()=>{this.props.history.push('/')}}>Home</div>
          <div className="collpase navbar-collapse">
          {(user.roles.includes('admin') && isAuthenticated) &&
            <ul className="navbar-nav mr-auto">
            <NavDropdown title="Import" id="basic-nav-dropdown">
            <LinkContainer to="/import/">
              <NavDropdown.Item>Import CSV</NavDropdown.Item>
            </LinkContainer>
            </NavDropdown>


            </ul>

          }
          </div>
          {isAuthenticated ? (
            <div style={styles.click} onClick={()=>this.props.logoutUser()}>log out - {user.username}</div>
          ) : (
            <div style={styles.click} onClick={()=>this.props.history.push('/login')}>log in</div>
          )}
        </nav>
      ) : null
    }
}

const styles = {
  click : {
    cursor: 'pointer'
  },
  logo : {
    width:120
  }
}
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps,{logoutUser,loginUser})(withRouter(TopNav))
