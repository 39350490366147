import React, { Component } from 'react';
//import logo from '../../tourystaLogo.png';
import { withRouter } from "react-router-dom";
import {baseUrl} from '../../config.js';
import { connect } from "react-redux";
import { registerUser } from "../../redux/actions/authActions";
import { loginUser } from "../../redux/actions/authActions";
import moment from 'moment';
import axios from 'axios';
import { WithContext as ReactTags } from 'react-tag-input';

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      links:[],
      loading:true,
      newlink:'',
      submitting:false,
      search:'',
      tags: [
      ],
      suggestions: [
        {id:"toystore",text:"Toy Store"},
        {id:"restaurant",text:"Restaurant"},
        {id:"kids",text:"Kids"},
        {id:"grocery store",text:"Grocery Store"},
        {id:"gym",text:"Gym"},
        {id:"bar",text:"Bar"},
        {id:"food",text:"Food"},
        {id:"beer",text:"Beer"},
        {id:"fine dining",text:"Fine Dining"},
        {id:"organic",text:"Organic"},
        {id:"local",text:"Local"},
        {id:"jewelry",text:"Jewelry"},
        {id:"art",text:"Art"}

      ],
    }
    this._tagHandleDelete = this._tagHandleDelete.bind(this);
    this._tagHandleAddition = this._tagHandleAddition.bind(this);

  }
  componentDidMount() {
    axios.post(baseUrl + '/links/list')
    .then(result=>{
      this.setState({
        loading:false,
        links:result.data
      })
    })
    .catch(error=>{
      this.setState({
        loading:false,
        error:true,
        links:[]
      })
    })
  }
  _tagHandleDelete(i) {
      const { tags } = this.state;
      this.setState({
       tags: tags.filter((tag, index) => index !== i),
      });
  }

  _tagHandleAddition(tag) {
      this.setState(state => ({ tags: [...state.tags, tag] }));
  }

  submit() {
    if(!this.state.newlink) {
      alert('need a link');
    } else {
      this.setState({
        submitting:true
      })
      console.log(this.state.tags);
      axios.post(baseUrl+'/links/add',{
        link:this.state.newlink,
        tags:this.state.tags
      })
      .then(result=>{
        this.setState({
          submitting:false,
          tags:[],
          newlink:''
        })
        axios.post(baseUrl + '/links/list')
        .then(result=>{
          this.setState({
            loading:false,
            links:result.data
          })
        })
        .catch(error=>{
          this.setState({
            loading:false,
            error:true,
            links:[]
          })
        })
      })
      .catch(error=>{
        console.log(error);
        this.setState({
          submitting:false
        })
      })
    }

  }
  search() {
    axios.post(baseUrl + '/links/list',{
      search:this.state.search
    })
    .then(result=>{
      this.setState({
        loading:false,
        links:result.data
      })
    })
    .catch(error=>{
      this.setState({
        loading:false,
        error:true,
        links:[]
      })
    })
  }
  tagsearch(tag) {
    this.setState({
      search:tag
    })
    axios.post(baseUrl + '/links/list',{
      search:tag
    })
    .then(result=>{
      this.setState({
        loading:false,
        links:result.data
      })
    })
    .catch(error=>{
      this.setState({
        loading:false,
        error:true,
        links:[]
      })
    })
  }
  clear() {
    this.setState({
      search:''
    });
    axios.post(baseUrl + '/links/list')
    .then(result=>{
      this.setState({
        loading:false,
        links:result.data
      })
    })
    .catch(error=>{
      this.setState({
        loading:false,
        error:true,
        links:[]
      })
    })
  }
  delete(link) {
    if(window.confirm('shall we delete ' + link + '?')) {
      axios.post(baseUrl + '/links/delete/' + link)
      .then(result=>{
        this.clear();
      })
      .catch(error=>{
        alert('error')
      })
    } else {
    }
  }
    render() {
      console.log(this.state);
      return this.state.loading ?(
        <div style={styles.homeContainer}>
          <div
            style={styles.button}
            >
            loading...
            </div>
        </div>
      ) : (
        <div style={styles.listContainer}>
          <div style={styles.addLink}>
          <input
            style={styles.inputforlink}
            value={this.state.newlink}
            onChange={(e)=>{
              this.setState({
                newlink:e.target.value
              })
            }}
           />
          </div>

          <div style={styles.tagsandsubmit}>
          <ReactTags tags={this.state.tags}
              suggestions={this.state.suggestions}
              handleDelete={this._tagHandleDelete}
              handleAddition={this._tagHandleAddition}
              delimiters={delimiters}
              allowDragDrop={false}
              style={{flex:1}}
               />
           {!this.state.submitting &&
             <button onClick={()=>{
               this.submit();
             }}>submit</button>
           }

          </div>
          <div style={styles.search}>
          <input
            style={styles.inputforSearch}
            value={this.state.search}
            onChange={(e)=>{
              this.setState({
                search:e.target.value
              })
            }}
           />
           <button style={styles.searchButton} onClick={()=>{this.search()}}>Search</button>
           <button style={{...styles.searchButton, marginLeft:'5px'}} onClick={()=>{this.clear()}}>Clear</button>
          </div>
          {this.state.links.map(link=>{
            return (
              <div style={styles.linkWrap} key={link._id}>
              <div style={styles.linkContainer} >
              <div style={styles.link}><a style={styles.href} href={link.link} target="_blank">{link.link}</a></div>
              <div style={styles.tags}>
              {link.tags.map((tag,index)=>{
                return (
                  <div style={styles.tag} key={index} onClick={()=>{this.tagsearch(tag.text)}}>{tag.text}</div>
                )
              })}
              </div>
              </div>
              <div style={styles.date}>
              {moment(link.createdAt).fromNow()} - {moment(link.createdAt).format('MMM Do, YYYY')}
              </div>
              <div style={styles.delete} onClick={()=>{
                this.delete(link._id)
              }}>
              x
              </div>
              </div>
            )
          })}
        </div>
      )
    }
}

const styles = {
  homeContainer : {
    display:'flex',
    flexDirection:'column',
    height:'calc(90vh - 140px)',
    justifyContent:'center',
    alignItems:'center'
  },
  button : {
    color:'white',
    backgroundColor:'lightblue',
    padding:10,
    borderRadius:3,
    cursor:'pointer'
  },
  href : {
    color:'black'
  },
  linkContainer : {
    display:'flex',
    flexDirection:'row'
  },
  linkWrap: {
    backgroundColor:'lightblue',
    padding:18,
    margin:5,
    borderRadius:3,
    position:'relative'

  },
  link:{
    flex:1,
    overflow:'hidden'
  },
  tags : {
    width:'30%',
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-evenly',
    borderLeft:'1px solid darkblue',
    alignItems:'center',
    flexWrap:'wrap'
  },
  tag : {
    background:'#106bbb',
    cursor:'pointer',
    padding:'4px 8px 4px 8px',
    color:'lightblue',
    borderRadius:3,
    marginBottom:'4px'
  },
  addLink : {
    display:'flex',
    padding:'5px'
  },
  tagsandsubmit : {
    display:'flex',
    padding:'5px'
  },
  search : {
    display:'flex',
    padding:'5px'
  },

  inputforlink : {
    flex:1,
    marginRight:'5px'
  },
  inputforSearch : {
    flex:1,
    marginRight:'5px'
  },
  date: {
    fontSize:9
  },
  delete : {
    width:15,
    height:17,
    backgroundColor:'indianred',
    justifyContent:'center',
    alignItems:'center',
    display:'flex',
    position:'absolute',
    top:5,
    left:5,
    color:'lightblue',
    cursor:'pointer',
    lineHeight:1,
    borderRadius:3
  }

}
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps,{ registerUser, loginUser })(withRouter(Home))
