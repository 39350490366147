import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { HashRouter } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./redux/actions/authActions";
import PrivateRoute from "./router/privateRoute";
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";

import { Provider } from "react-redux";
import store from "./redux/store.js";

import Home from "./components/home/home";
import Login from './components/auth/Login.js';
import Register from './components/auth/Register.js';
import Import from './components/import/import.js';
import TopNav from './components/navbar/topnav.js';


// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "./login";
  }
}

console.log('LOADING')
console.log(store.getState());

class App extends Component {
  componentDidMount() {
  }
  render() {
    return (
      <Provider store={store}>
      <HashRouter>
      <div className="container">
      <TopNav/>
          <Route exact path="/login" component={Login} />
          <Switch>
          <PrivateRoute exact path="/" component={Home} />
          <PrivateRoute exact path="/import/" component={Import} />
          </Switch>
        </div>
      </HashRouter>
      </Provider>
    );
  }
}

export default App;
