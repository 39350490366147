import React, { Component } from 'react';
import RegisterInput from './RegisterInput';
export default class RegisterOne extends Component {


  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {
  }
    render() {
      if(this.props.currentStep !== 1) return (null)
      return (
        <div style={styles.registerContainer}>
        <RegisterInput
          label="First Name"
          name="firstName"
          value={this.props.firstName}
          onChange={this.props.handleChange}
        />
          <RegisterInput
            label="Last Name"
            name="lastName"
            value={this.props.lastName}
            onChange={this.props.handleChange}
          />
        </div>
      )
    }
}

const styles = {
  registerContainer : {

  },
  loginForm: {
    background:'#f26134',
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    padding:15,
    borderRadius:5
  },
  inputContainer: {
    marginBottom:50
  },
  formInput : {
    minWidth:350,
    borderBottom:'1px solid #f26134',
    borderTop:'none',
    borderLeft:'none',
    borderRight:'none',
    background:'rgba(255,255,255,0)',
    color:'#f26134',
    marginTop:15
  },
  inputLabel : {
    color:'#f26134',
    fontSize:34
  },
  button : {
    marginTop:15,
    padding:8,
    background:'red'
  },
  nextButton : {
    background:'#f26134',
    color: 'white',
    padding:'8px 20px 8px 20px',
    marginTop:45,
    width:350,
    textAlign:'center',
    borderRadius:'5px'
  },
  actionBar:{
    display:'flex',
    flexDirection:'row',
    width:'100%',
    justifyContent:'space-evenly',
    marginTop:13
  },
  actionItem: {
    fontSize:12
  },
  image : {
    width:200
  }
}
